import React from "react";

const brandContent = [
  {
    delayAnimation: "0",
    imgName: "Roadguard-logo",
    title: "RoadGuard",
    link: "https://www.roadguard.no/"
  },
  {
    delayAnimation: "0",
    imgName: "BCA_logo_dark",
    title: "Bonseyes Community Association",
    link: "https://bonseyes-association.org/"
  },
  {
    delayAnimation: "0",
    imgName: "sensory-robotics-logo",
    title: "Sensory Robotics",
    link: "https://www.sensoryrobotics.com/"
  },
  {
    delayAnimation: "0",
    imgName: "Lot-logo",
    title: "LoTTechnology",
    link: "https://lottechnology.com/"
  }
];

const BrandThree = () => {
  return (
    <ul
      className="
          ptf-clients-list
          ptf-clients-list--center
          ptf-clients-list--style-2
        "
      data-columns="3"
    >
      {brandContent.map((val, i) => (
        <li
          className="ptf-clients-list__item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <a href={`${val.link}`} style={{maxWidth: 20 + "%"}}>
          <img
            src={`assets/img/root/partners/${val.imgName}.png`}
            alt={`${val.title}`}
            loading="lazy"
          />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default BrandThree;
