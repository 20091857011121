import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const BlogGridContent = [
  {
    img: "garvit-3IyjBegTXLA-unsplash.jpg",
    date: "June 7, 2023",
    title: "Insights into the Future: Unleashing the Power of Computer Vision",
    descriptions: ` Discover the cutting-edge world of computer vision as we delve into its transformative capabilities and explore the limitless potential it holds. From groundbreaking advancements in image recognition to revolutionary applications in autonomous vehicles and medical diagnostics, join us on a captivating journey through the realm of computer vision, where we unveil the unseen and embrace a future defined by visual intelligence.`,
  },
  {
    img: "kirill-tonkikh-VHMOjviPgcU-unsplash.jpg",
    date: "March 11, 2023",
    title: "Demystifying Computer Vision: From Theory to Practical Applications",
    descriptions: `Embark on a captivating expedition through the realm of computer vision as we unravel its mysteries and bridge the gap between theoretical concepts and real-world applications. Join us on a fascinating exploration of the inner workings of computer vision algorithms, dive into the nuances of deep learning models, and discover how this powerful technology is revolutionizing industries such as healthcare, robotics, and augmented reality. From foundational principles to hands-on insights, gain a comprehensive understanding of computer vision's practical applications and unlock its potential in shaping the future.`,
  }
];

const BlogGridSLider = () => {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="swiper-slide grid-slider">
        <Slider {...settings} className="arrow-none">
          {BlogGridContent.map((val, i) => (
            <div className="ptf-news-slide" key={i}>
              <div className="ptf-news-slide__media">
                <img
                  src={`assets/img/blog/slider/${val.img}`}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="ptf-news-slide__content">
                <div className="ptf-news-slide__meta">
                  <span className="cat">Inspiration</span>
                  <span className="date">{val.date}</span>
                </div>
                <h3 className="ptf-news-slide__title">
                  <Link to="/blog-details">{val.title}</Link>
                </h3>
                <div className="ptf-news-slide__excerpt">
                  <p>{val.descriptions}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default BlogGridSLider;
