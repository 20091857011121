import React, {useContext} from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {MainContext} from "../../context/MainContext";

const FaqContent = [
  {
    title: {
      "en": "How much do your consultancy services cost?",
      "no": "Hvor mye koster dine konsulenttjenester?"
    },
    desc: {
      "en": "Our consultancy services are tailored to each client's specific needs, so the cost can vary based on the scope of work and the level of expertise required. We provide transparent pricing and work within your budget to deliver the best value for your investment. Contact us for a detailed proposal and pricing information.",
      "no": "Våre konsulenttjenester er skreddersydd til hver enkelt kundes spesifikke behov, slik at kostnadene kan variere basert på omfanget av arbeidet og nivået av kompetanse som kreves. Vi tilbyr transparente priser og jobber innenfor budsjettet ditt for å levere best mulig verdi for investeringen din. Kontakt oss for et detaljert forslag og prisinformasjon."
    },
    expand: "a",
  },
  {
    title: {
      "en": "How experienced are your AI consultants?",
      "no": "Hvor erfarne er AI-konsulentene dine?"
    },
    desc: {
      "en": "Our AI consultants are experts in their fields with extensive experience in AI research, development, and implementation. They possess deep knowledge of cutting-edge AI technologies and methodologies. Our team is skilled in applying AI across various industries, enabling us to deliver tailored solutions that align with our clients' specific needs.",
      "no": "Våre AI-konsulenter er eksperter på sine felt med lang erfaring innen AI-forskning, utvikling og implementering. De har dyp kunnskap om banebrytende AI-teknologier og -metoder. Teamet vårt er dyktig i å bruke AI på tvers av ulike bransjer, noe som gjør oss i stand til å levere skreddersydde løsninger som er i tråd med våre kunders spesifikke behov."
    },
    expand: "b",
  },
  {
    title: {
      "en": "Do I need to have existing AI expertise to work with your consultancy firm?",
      "no": "Trenger jeg å ha eksisterende AI-ekspertise for å jobbe med konsulentfirmaet ditt?"
    },
    desc: {
      "en": "No, you do not need to have prior AI expertise. Our consultancy firm caters to organizations at different stages of their AI journey. Whether you are new to AI or have some existing AI initiatives, our team will work closely with you to understand your requirements and provide guidance and support throughout the process.",
      "no": "Nei, du trenger ikke ha tidligere AI-ekspertise. Vårt konsulentfirma henvender seg til organisasjoner på forskjellige stadier av deres AI-reise. Enten du er ny på AI eller har noen eksisterende AI-initiativer, vil teamet vårt jobbe tett med deg for å forstå kravene dine og gi veiledning og støtte gjennom hele prosessen."
    },
    expand: "c",
  },
  {
    title: {
      "en": "How long does an AI engagement with your consultancy firm typically last?",
      "no": "Hvor lenge varer vanligvis et AI-engasjement med konsulentfirmaet ditt?"
    },
    desc: {
      "en": "The duration of an AI engagement can vary depending on the complexity of the project and the specific objectives. Some projects may be shorter in duration, such as conducting an AI readiness assessment, while others may be longer-term engagements involving AI system implementation and optimization. We work closely with our clients to establish project timelines and milestones.",
      "no": "Varigheten av et AI-engasjement kan variere avhengig av kompleksiteten til prosjektet og de spesifikke målene. Noen prosjekter kan ha kortere varighet, for eksempel å gjennomføre en AI-beredskapsvurdering, mens andre kan være langsiktige engasjementer som involverer AI-systemimplementering og -optimalisering. Vi jobber tett med våre kunder for å etablere prosjekttidslinjer og milepæler."
    },
    expand: "d"
  }
];

const Faq = () => {
  const {language,} = useContext(MainContext);
  return (
    <>
      <dl className="ptf-accordion">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem key={i} uuid={item.expand}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <dt>{item.title[language]}</dt>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel>
                <dd>{item.desc[language]}</dd>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </dl>
    </>
  );
};

export default Faq;
