import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ContactFormContent from "../content/contact/ContactForm.json"
import {MainContext} from "../context/MainContext";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import ContactPageContent from "../content/contact/ContactPage.json";

const ContactForm = () => {
  const {language,} = useContext(MainContext);

  const [captchaResponse, setCaptchaResponse] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(ContactFormContent.name_is_required[language]),
    organization: Yup.string().required(ContactFormContent.organization_name_is_required[language]),
    projectGoal: Yup.string().required(ContactFormContent.project_goal_is_required[language]),
    email: Yup.string()
      .required(ContactFormContent.email_is_required[language])
      .email(ContactFormContent.email_format_is_wrong[language])
  });

  const formOptions = {resolver: yupResolver(validationSchema)};
  // get functions to build form with useForm() hook
  const {register, handleSubmit, formState} = useForm(formOptions);
  const {errors} = formState;

  function onChange(value) {
    setCaptchaResponse(value);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function onSubmit(data, e) {
    // display form data on success
    var apiUrl = `https://faintech.no:8443/api/contact/sendEmail`;
    var headers = {
      headers: {
        "captcha-response": captchaResponse,
        "Content-Type": "application/json"
      }
    };
    axios.post(apiUrl, data, headers).then((response) => {
      setMessageSent(true);
      scrollToTop();
    });
  }

  return (
    messageSent ?
      <>
        <div className="ptf-form-group">
          <h4 className={"has-accent-1 text-decoration-underline"}>{ContactFormContent.message_sent[language]}</h4>
        </div>
      </> : <>
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">
          {ContactPageContent.contact_tell_us_about_project[language]}
        </h5>
        <div
          className="ptf-spacer"
          style={{"--ptf-xxl": "3.125rem"}}
        ></div>
        <form onSubmit={handleSubmit(onSubmit)} className="contact_form">
          <div className="ptf-form-group">
            <label data-number="01">{ContactFormContent.your_name[language]}</label>
            <input
              type="text"
              name="name"
              {...register("name")}
              className={`${errors.name ? "is-invalid" : ""}`}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name?.message}</div>
            )}
          </div>
          {/* End .ptf-form-group */}

          <div className="ptf-form-group">
            <label data-number="02">{ContactFormContent.organization_name[language]}</label>
            <input
              type="text"
              name="organization"
              {...register("organization")}
              className={`${errors.organization ? "is-invalid" : ""}`}
            />
            {errors.organization && (
              <div className="invalid-feedback">{errors.organization?.message}</div>
            )}
          </div>
          {/* End .ptf-form-group */}

          <div className="ptf-form-group">
            <label data-number="03">{ContactFormContent.your_email[language]}</label>
            <input
              name="email"
              type="text"
              {...register("email")}
              className={` ${errors.email ? "is-invalid" : ""}`}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email?.message}</div>
            )}
          </div>
          {/* End .ptf-form-group */}

          <div className="ptf-form-group">
            <label data-number="04">{ContactFormContent.about_project[language]}</label>
            <textarea
              name="projectGoal"
              {...register("projectGoal")}
              className={`${errors.projectGoal ? "is-invalid" : ""}`}
            />
            <input type={"hidden"} name="language" value={language}/>
            {errors.projectGoal && (
              <div className="invalid-feedback">{errors.projectGoal?.message}</div>
            )}
          </div>
          {/* End .ptf-form-group */}

          <div className="ptf-form-group">
            <ReCAPTCHA
              sitekey="6LccLckpAAAAAHn10l2koKQ8XaAZhCILpn69kf25"
              onChange={onChange}
            />
          </div>
          {/* End .ptf-form-group */}


          <button className="ptf-submit-button">
            {ContactFormContent.submit[language]}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 17 17"
            >
              <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z"/>
            </svg>
          </button>
          {/* End .ptf-submit-button */}
        </form>
      </>

  );
};

export default ContactForm;
