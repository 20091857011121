import {createContext, useEffect, useState} from 'react';

export const MainContext = createContext();

export const MainProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  return (
    <MainContext.Provider value={{ language, setLanguage }}>
      { children }
    </MainContext.Provider>
  )
}
