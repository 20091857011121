import React, { useState } from "react";
import { Link } from "react-router-dom";
import DropdownMenu from "./menu/DropdownMenu";
import {MainContext} from "../../context/MainContext";
import { useContext } from 'react';

const HeaderDefault = () => {
  const { language, setLanguage } = useContext(MainContext);

  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <header className="ptf-header ptf-header--style-3 ptf-header--opaque">
        <div
          className={
            navbar
              ? "ptf-navbar ptf-navbar--main ptf-navbar--sticky ptf-navbar--fixed"
              : "ptf-navbar ptf-navbar--main ptf-navbar--sticky"
          }
        >
          <div className="container-xxl">
            <div className="ptf-navbar-inner">
              {/* <!--Logo--> */}
              <Link className="ptf-navbar-logo" to="/">
                <img
                  className="black"
                  src="assets/img/root/logo-fain-white.jpg"
                  alt=""
                  loading="lazy"
                  width="200rem"
                />
              </Link>
              {/* <!--Navigation--> */}
              <nav className="ptf-nav ptf-nav--default">
                {/* <!--Menu--> */}
                <DropdownMenu />
              </nav>
              {/* <!--Buttons--> */}

              {/* <!--Language Switch--> */}
              <div className="ptf-offcanvas-menu__header">
                <div className="ptf-language-switcher">

                  <a className={language === 'en' ? 'is-active' : ''} href="#en" onClick={() => setLanguage('en')}>
                    Eng
                  </a>
                  {/* eslint-disable */}
                  <a className={language === 'no' ? 'is-active' : ''} href="#no" onClick={() => setLanguage('no')}>Nor</a>
                </div>
              </div>

              {/* Toggle bar icon */}
            </div>
          </div>
        </div>
      </header>
      {/* End header */}

    </>
  );
};

export default HeaderDefault;
